import * as React from "react"
import Footer from '../../components/layout/components/footer';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import Menu from '../../components/menu/menu';
import MedBizGifLogo from '../../images/general/construction.gif';

const ctnStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  marginTop: '8rem',
}

const gifStyle = {
  width: '70%',
  height: '70%',
}

const UnderConstructionPage = () => {

  const {t} = useTranslation();

  return (
    <>

      <Menu headerImg={false}/>

      <Seo
        title={t`under-construction.page-title`}
        description={t`under-construction.page-description`}
        ogImage={ogImage}
      />

      <main className="container" style={ctnStyle}>

        <h1>{t`under-construction.title`}</h1>
        <p>{t`under-construction.text`}</p>
        <img src={MedBizGifLogo} alt="MedBiz logo gif" style={gifStyle}/>

      </main>

      <Footer/>
    </>
  )
}

export default UnderConstructionPage;
